<template>
  <item-edit-view
    title="Специальность"
    :subtitle="item ? item.name : null"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card>
      <v-form v-if="!loading && item">
        <v-card-text>
          <div>
            <div class="body-1">Дисклеймер</div>
            <v-input :value="item.disclaimer">
              <quill
                v-model="item.disclaimer"
                :options="quillToolbar"
              >
              </quill>
            </v-input>
          </div>

          <v-checkbox
            label="Не показывать на сайте"
            v-model="item.private"
          />

          <v-checkbox
            label="Экспортировать в yandex фид докторов"
            v-model="item.exportToYandexFeedAsDoctor"
          />

          <div>
            <label for="defaultServiceId">Дефолтная услуга для записи на прием в клинике</label>
            <multiselect
              id="defaultServiceId"
              @search-change="findServices"
              :loading="services.isLoading"
              :internal-search="false"
              :options="services.items"
              :allow-empty="true"
              :show-labels="true"
              :searchable="true"
              placeholder="Дефолтная услуга для записи на прием в клинике"
              track-by="id"
              label="name"
              :value="defaultService"
              @input="changeDefaultService"
            >
            </multiselect>
          </div>

          <div>
            <label for="defaultOnlineServiceId">Дефолтная услуга для записи на онлайн прием</label>
            <multiselect
              id="defaultServiceId"
              @search-change="findServices"
              :loading="services.isLoading"
              :internal-search="false"
              :options="services.items"
              :allow-empty="true"
              :show-labels="true"
              :searchable="true"
              placeholder="Дефолтная услуга для записи на онлайн прием"
              track-by="id"
              label="name"
              :value="defaultOnlineService"
              @input="changeDefaultOnlineService"
            >
            </multiselect>
          </div>

        </v-card-text>

      </v-form>
    </v-card>
  </item-edit-view>
</template>

<script>
  import servicesService from '../../services/service'
  import specialitiesService from '../../services/specialities'
  import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
  import { QUILL_BASIC } from '../../components/elements/quilljs/configs'
  import debounce from 'lodash/debounce'

  export default {
    mixins: [createOrUpdateViewMixin(specialitiesService)],
    data () {
      return {
        services: {
          items: [],
          isLoading: false,
        },
        quillToolbar: QUILL_BASIC,
        defaultService: null,
        defaultOnlineService: null
      }
    },
    methods: {
      async afterFetch () {
        this.services.isLoading = true
        try {
          if (this.item.defaultServiceId) {
            let r = await servicesService.get(this.item.defaultServiceId)
            this.defaultService = r
          }
          if (this.item.defaultOnlineServiceId) {
            let r = await servicesService.get(this.item.defaultOnlineServiceId)
            this.defaultOnlineService = r
          }
          this.services.isLoading = false
        } catch (e) {
          console.error(e)
          this.services.isLoading = false
        }
      },
      changeDefaultService (service) {
        this.defaultService = service
        this.item.defaultServiceId = service ? service.id : null
      },
      changeDefaultOnlineService (service) {
        this.defaultOnlineService = service
        this.item.defaultOnlineServiceId = service ? service.id : null
      },
      findServices: debounce (async function (query) {
        if (this.services.isLoading) {
          return
        }
        this.services.isLoading = true
        try {
          let r = await servicesService.getAll({ specialityId: this.$route.params.id, search: query })
          this.services.items = r.items
          this.services.isLoading = false
        } catch (e) {
          console.error(e)
          this.services.isLoading = false
        }
      }, 1000)
    }
  }
</script>
